<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="9" md="9" lg="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/book.svg')" alt="icono de generación ficha producto" width="28" height="28">
                    Generación automática ficha-producto
                </span>
            </v-col>
        </v-row>
      <template v-if="loading_select">
        <Loading/>
      </template>
        <v-row v-else>
            <v-col cols="12">
                <v-form class="editor" @submit.prevent="sendForm()">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Título del curso"
                                    v-model="form_data.course" variant="underlined"
                                    hint="Ej: CURSO DE COCINA ESPAÑOLA: Curso de preparación para trabajar como cocinero de comida española"
                                    class="text-field-primary field-with-label"
                                    :class="{error: variableIsDefined(form_error.course)}"
                                    persistent-hint
                                ></v-text-field>
                                <span v-if="form_error.course"
                                      class="input-error msg-error"
                                >
                                {{ form_error.course }}
                            </span>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Información adicional del curso"
                                    v-model="form_data.extrainformation_to_prompts" variant="underlined"
                                    class="text-field-primary field-with-label"
                                    hint="Ej: Es importante que tengas en cuenta que este es un curso preparatorio que permite optar a trabajar como cocinero"
                                    persistent-hint
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Información añadida manualmente"
                                    v-model="form_data.extrainformation_to_fichaproducto " variant="underlined"
                                    class="text-field-primary field-with-label"
                                    hint="Los valores independientes deben ir separados por punto y coma .Ej: keywords:cocina,sartén;salidas_laborales:chef"
                                    persistent-hint
                                    :class="{error: variableIsDefined(form_error.extrainformation_to_fichaproducto)}"
                                ></v-text-field>
                                <span class="help-text">Valores permitidos:</span>
                                <ul class="help-text">
                                    <li>descripcion</li>
                                    <li>keywords</li>
                                    <li>objetivos</li>
                                    <li>a_quien_va_dirigido</li>
                                    <li>para_que_te_prepara</li>
                                    <li>salidas_laborales</li>
                                </ul>
                                <span v-if="form_error.extrainformation_to_fichaproducto" class="input-error msg-error">{{
                                    form_error.extrainformation_to_fichaproducto
                                    }}</span>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    label="Temario"
                                    v-model="form_data.temario"
                                    hide-details
                                    variant="outlined"
                                    class="input-error text-area-primary personalize-scroll"
                                    outline
                                    bg-color="white"
                                    density="compact"
                                    rows="3"
                                ></v-textarea>
                                <span class="help-text">Ej:</span>
                                <ul class="help-text">
                                    <li>1. Proceso de almacenamiento de alimentos</li>
                                    <li>1.2.Técnicas de conservación de alimentos, productos y enseres</li>
                                    <li>2. Técnicas de limpieza de cocina</li>
                                    <li>3. Proceso de elaboración de la lista de la compra.</li>
                                </ul>
                            </v-col>
                            <v-col cols="12">
                                <p>Campos a generar</p>
                                <p style="font-size: 12px"><strong>Aviso</strong>: Para poder marcar el nuevo campo "Generar guiones" se deberan marcar también los campos "Descripción", "Objetivos", "Para que te prepara", "A quien va dirigido" y "Salidas Laborales"</p>
                                <v-row>
                                    <template v-for="field in available_fields">
                                        <v-col cols="12" md="4">
                                            <div style="display: inline-block; width: auto ">
                                                <v-checkbox
                                                    :label="field.name"
                                                    v-model="selected_fields"
                                                    :value="field.value"
                                                    color="blue"
                                                    :key="field"
                                                    hide-details
                                                ></v-checkbox>
                                            </div>
                                        </v-col>
                                    </template>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="4">
                                <SelectTertiary
                                    :items="type_course"
                                    item-title="nombre"
                                    item-value="nombre"
                                    v-model="form_data.tipo_curso"
                                    placeholder="Tipo de curso"
                                    class="input-error"
                                    :error="variableIsDefined(form_error.tipo_curso)"
                                >
                                </SelectTertiary>
                                <span v-if="form_error.tipo_curso"
                                      class="input-error msg-error"
                                >
                                {{ form_error.tipo_curso }}
                                </span>
                            </v-col>
                            <v-col cols="12" md="4">
                                <SelectTertiary
                                    :items="model_values"
                                    v-model="form_data.modelo"
                                    placeholder="Modelo"
                                    class="input-error"
                                    bg-color="white"
                                    :error="variableIsDefined(form_error.modelo)"
                                >
                                </SelectTertiary>
                                <span v-if="form_error.modelo"
                                      class="input-error msg-error"
                                >
                                {{ form_error.modelo }}
                                </span>
                            </v-col>
                            <v-col cols="12" md="4">
                                <SelectTertiary
                                    :items="languages"
                                    v-model="form_data.language"
                                    placeholder="Idioma"
                                    class="input-error"
                                    bg-color="white"
                                    :error="variableIsDefined(form_error.language)"
                                    item-title="nombre"
                                >
                                </SelectTertiary>
                                <span v-if="form_error.modelo"
                                      class="input-error msg-error"
                                >
                                {{ form_error.modelo }}
                                </span>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-checkbox
                                    label="Formación continuada (Marcar para que aparezca de forma explicita que el curso no es habilitante)"
                                    v-model="form_data.formacion_continuada"
                                    :true-value="1"
                                    :false-value="0"
                                    color="blue"
                                    hide-details
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-if="variableIsDefined(form_error['generated_fields'])" cols="12" class="text-center">
                                <span v-if="form_error.modelo" class="custom-error">
                                {{ form_error['generated_fields'] }}
                                </span>
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <v-btn @click="sendForm"
                                       class="btn button-bg-blue btn-xl ml-3"
                                       :disabled="loading_small">
                                    Generar
                                </v-btn>
                                <p v-if="loading_small">Generando contenido... Esto puede tardar varios minutos</p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-col>
        </v-row>

        <v-row v-if="loading_small">
            <v-col class="box-loading text-center">
                <img class="loading-gif-s" :src="require('@/assets/img/loading.gif')"
                     alt="Loading Gif">
            </v-col>
        </v-row>
        <v-row v-if="generated_content && !loading_small">
            <template v-for="(value, title) in generated_content">
                <v-col cols="12">
                    <v-card variant="outlined">
                        <v-card-title>
                            <p>{{ title }}
                                <v-tooltip text="Copiar">
                                    <template v-slot:activator="{ props }">
                                        <img v-bind="props" class="icon-copy"
                                             :src="require('@/assets/icons/clipboard.svg')"
                                             alt="Clipboard icon" @click="copy(value)"/>
                                    </template>
                                </v-tooltip>
                            </p>
                        </v-card-title>
                        <v-card-text style="white-space: pre-wrap">
                            {{ value }}
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
        </v-row>
        <v-row v-if="response_error && !loading_small">
            <p class="error-msg">ERROR: {{ response_error }}</p>
        </v-row>
    </v-container>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {generateAutomaticContentForCourseWS, getTypeCoursesWS} from "@/utils/courses";
import {copyClipboard, showNotification, variableIsDefined} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";
import * as Yup from "yup";
import SelectTertiary from "@/components/Generic/SelectTertiary.vue";
import {useStore} from "vuex";

export default {
    name: "FormComment",
    methods: {variableIsDefined},
    components: {SelectTertiary, Loading},
    setup() {
        const store = useStore();
        const form_data = ref({});
        const form_error = ref({});
        const loading_small = ref(false);
        let loading_select = ref(false);
        const generated_content = ref();
        const response_error = ref();
        const model_values = ref(['gpt-4o', 'gpt-4o-mini']);
        let type_course = ref([]);

        let all_languages = computed(() => store.getters.getStoredLanguages).value;
        let languages = ref([])

        const available_fields = ref([
            {name: 'Descripción', value: 'generar_descripcion'},
            {name: 'Keywords', value: 'generar_keywords'},
            {name: 'Objetivos', value: 'generar_objetivos'},
            {name: 'A quien va dirigido', value: 'generar_a_quien_va_dirigido'},
            {name: 'Para que te prepara', value: 'generar_para_que_te_prepara'},
            {name: 'Salidas laborales',  value: 'generar_salidas_laborales'},
            {name: 'Competencias básicas', value: 'generar_competencias_basicas'},
            {name: 'Generar guion producto', value: 'generar_guion_producto'},
            {name: 'Generar intro guion', value: 'generar_intro_guion'},
        ])

        const selected_fields = ref(['generar_descripcion', 'generar_keywords', 'generar_objetivos', 'generar_a_quien_va_dirigido','generar_para_que_te_prepara', 'generar_salidas_laborales'])
        const deselected_fields = []

        onBeforeMount(async () => {
            loading_select.value = true

            languages.value = new Set(all_languages.map(lang => lang.nombre))

            let response = await getTypeCoursesWS();
            if (response['result']){
                type_course.value = response['data']
            }else{
                showNotification('error', response['errors']);
            }
            loading_select.value = false
        })

        watch(selected_fields, (value, oldValue)=> {
            let selected = oldValue.filter(x => !value.includes(x)).concat(value.filter(x => !oldValue.includes(x)))[0];

            if (value.length < oldValue.length){
                if(!deselected_fields.some(element => element === selected)){
                    deselected_fields.push(selected)
                }
            }else{
                let index = deselected_fields.findIndex(element => element === selected)
                if (index > -1){
                    deselected_fields.splice(index, 1)
                }
            }

            if (
                value.includes("generar_guion_producto")
                && (!value.includes('generar_descripcion')
                || !value.includes('generar_objetivos')
                || !value.includes('generar_para_que_te_prepara')
                || !value.includes('generar_a_quien_va_dirigido')
                || !value.includes('generar_salidas_laborales'))
            )
            {
                selected_fields.value = selected_fields.value.filter(str => str !== 'generar_guion_producto')
            }

            if (
                value.includes("generar_intro_guion")
                && (!value.includes('generar_descripcion')
                    || !value.includes('generar_objetivos')
                    || !value.includes('generar_para_que_te_prepara')
                    || !value.includes('generar_a_quien_va_dirigido')
                    || !value.includes('generar_salidas_laborales'))
            )
            {
                selected_fields.value = selected_fields.value.filter(str => str !== 'generar_intro_guion')
            }

        })

        async function sendForm() {
            loading_small.value = true
            response_error.value = ""
            await validateForm()
            if (Object.keys(form_error.value).length === 0 && selected_fields.value.length > 0) {
                try {
                    form_data.value['selected_fields'] = selected_fields
                    let response = await generateAutomaticContentForCourseWS(form_data.value);
                    if (response['result']) {
                        generated_content.value = response['data'];
                    } else {
                        let text = response['error']
                        if (typeof response['error'] === "object") {
                            if (response['error'].title !== undefined) {
                                text = response['error'][0].title
                            }
                        }

                        if (text.search('The server is overloaded or not ready yet') !== -1) {
                            generated_content.value = response['data'];
                        }
                        response_error.value = response['error']
                    }
                } catch (e) {
                    showNotification('error', 'Se ha producido un error al lanzar la petición')
                }
            }
            loading_small.value = false
        }

        const validateForm = async () => {
            form_error.value = {};
            const schemaForm = Yup.object().shape({
                course: Yup.string().required(true),
                modelo: Yup.string().required(true),
                tipo_curso: Yup.string().required(true),
                language: Yup.string().required(true),
            })
            //Validación extrainformation
            if (variableIsDefined(form_data.value.extrainformation_to_fichaproducto)) {
                let index = form_data.value.extrainformation_to_fichaproducto.search(':')
                if (index === -1) {
                    form_error.value['extrainformation_to_fichaproducto'] = "Formato incorrecto"
                }
            }

            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});

            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = "Es obligatorio rellenar este campo."
                })
            }

            if(selected_fields.value.length === 0){
                form_error.value['generated_fields'] = "Debe marcar al menos un campo a generar."
            }

        }

        const copy = async (valor) => {
            await copyClipboard(valor)
        }

        return {
            sendForm,
            copy,
            variableIsDefined,

            available_fields,
            selected_fields,
            response_error,
            loading_small,
            loading_select,
            form_data,
            form_error,
            generated_content,
            model_values,
            type_course,
            languages,
        }
    }
}
</script>

<style scoped lang="scss">
.icon-copy {
  max-width: 20px;
  margin-left: 12px;

  &:hover {
    cursor: pointer;
  }
}

.help-text {
  margin-left: 0;
  padding-left: 0;
  font-size: 12px;

  li {
    list-style: inside;
  }
}

.custom-error{
    color: $error;
    font-size: 14px;
    padding-top: 5px;
    font-style: italic;
}
</style>
